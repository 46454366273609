import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Chevron from '../../assets/icons/Chevron.js'
import MigracionLogo from '../../assets/img/migracion-logo.js'
import OperacionLogo from '../../assets/img/operacion-logo.js'
import ServicioLogo from '../../assets/img/servicios-logo.js'
import InfraLogo from '../../assets/img/infra-logo.js'
import migImg from '../../assets/img/migracion-img.png'
import opImg from '../../assets/img/operacion-img.png'
import servImg from '../../assets/img/servicios-img.png'
import infraImg from '../../assets/img/infra-img.png'
import useWindowSize from '../../hooks/useWindowSize.ts'
import { useState, useEffect } from 'react'


const Services = () => {
  const windowSize = useWindowSize()
  const [isOpened, setOpened] = useState(null)
  const [isMobile, setMobile] = useState(false)


  const toggle = (index) => {
    if ( isMobile === false ) {
      return
    }
    if (isOpened === index) {
      return setOpened(null)
    }
    setOpened(index)
  }

  useEffect(() => {
    if ((windowSize.width !== undefined && windowSize.width > 640)) {
			setMobile(false)
		} else {
			setMobile(true)
		}
  }, [windowSize]);

  return (
    <Styled>
      <Grid container justifyContent='center' spacing={0}>
        <Grid item xs={12}>
          <div align='center' className='title'>
            Servicios
          </div>
        </Grid>

        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='colored-card color1' onClick={() => toggle(0)}>
            <div className='img-container'>
              <img width='100%' src={migImg} object-position='0 100%'  object-fit='cover' alt='migracion imagen' />
            </div>
            <div className='logo-container'>
              <MigracionLogo className="logo" />
              { ( isMobile ) && (
                <Chevron width={24} className={isOpened===0 ? 'rotate-180' : ''} />
              )}
            </div>
            { ( !isMobile || isOpened===0 ) && (
              <div className='text'>
                Llevamos aplicaciones, bases de datos y el negocio de nuestros clientes a la Nube (Lift & Shift / Replatform, otros), 
                ayudando a seleccionar la mejor tecnología para la migración basada en las necesidades de su negocio.
              </div>
            )}
          </div>
        </Grid>

        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='colored-card color2' onClick={() => toggle(1)}>
            <div className='img-container'>
              <img width='100%' src={opImg} object-position='0 100%' object-fit='cover' alt='operacion imagen' />
            </div>
            <div className='logo-container'>
              <OperacionLogo className="logo" />
              { ( isMobile ) && (
                  <Chevron width={24} className={isOpened===1 ? 'rotate-180' : ''} />
                )}
            </div>
            { ( !isMobile || isOpened===1 ) && (
              <div className='text'>
                Todos los servicios de operación en la Nube: desde la revisión de los dashboards y la ejecución de pruebas seleccionadas, 
                para cada aplicación de forma periódica, hasta la Mesa de Soporte con un team certificado de manera constante para Soporte 
                Técnico de O&M 7x24 (N1,2 y 3) y Soporte Técnico de O&M 5x8  (N1, 2 y 3).
              </div>
            )}
          </div>
        </Grid>

        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='colored-card color3' onClick={() => toggle(2)}>
            <div className='img-container'>
              <img width='100%' src={servImg} object-position='0 100%' object-fit='cover' alt='servicios imagen' />
            </div>
            <div className='logo-container'>
              <ServicioLogo className="logo" />
              { ( isMobile ) && (
                <Chevron width={24} className={isOpened===2 ? 'rotate-180' : ''} />
              )}
            </div>
            { ( !isMobile || isOpened===2 ) && (
              <div className='text'>
                Nuestro equipo de expertos certificados se suman a tus proyectos para maximizar los resultados de negocio, 
                brindando servicios profesionales en la Nube en consultoría, ingeniería de software, database management, 
                QA & testing y networking.
              </div>
            )}
          </div>
        </Grid>

        <Grid item container justifyContent="center" xs={12} md={5}>
          <div className='colored-card color4' onClick={() => toggle(3)}>
            <div className='img-container'>
             <img width='100%' src={infraImg} object-position='0 100%' object-fit='cover' alt='infra imagen' />
            </div>
            <div className='logo-container'>
              <InfraLogo className="logo" />
              { ( isMobile ) && (
                <Chevron width={24} className={isOpened===3 ? 'rotate-180' : ''} />
              )}
            </div>
            { ( !isMobile || isOpened===3 ) && (
              <div className='text'>
                Te acercamos la contratación directa de toda oferta de la Infraestructura de los principales Vendors de Nube, 
                en base al requerimiento de tus negocios. Diseñamos la estrategia, implementación y administración como aaS, ya sea Nube Pública, 
                Privada o Híbrida.
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Services

const Styled = styled.section`
  margin: 48px 0 86px;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 640px){
    margin: 0 0 48px 0;
  }

  .title {
    color: #030144;
    font-family: 'Century Gothic';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 32px;
  }

  .colored-card {
    margin: 20px;
    height: 624px;
    width: 453px;
    border-radius: 20px;
    @media (max-width: 640px){
      margin: 0;
      border-radius: 0 !important;
      height: auto;
      padding-bottom: 14px;
    }
  }

  .img-container {
    @media (max-width: 640px){
      height: 240px;
      overflow: hidden;
      position: relative;
    }
    img {
      @media (min-width: 640px){
        margin: 0;
        position: relative;
      }
      @media (max-width: 640px)
        display: block;
        position: absolute;
        bottom: 0;
        margin: 0;
      }
    }
  }

  .color1 {
    color: #FFFFFF;
    background-color: #1F75FF;
  }

  .color2 {
    background-color: #00E6EE;
    color: #030144;
  }

  .color3 {
    background-color: #915CFD;
    color: #FFFFFF;
  }

  .color4 {
    background-color: #49EAB1;
    color: #030144;
  }

  .logo {
    margin-top: 22px;
    margin-left: 27px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 29px;
  }

  .text {
    padding: 10px 27px;
    font-family: 'Century Gothic';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px; 
  }

  .logo-container {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }
`
