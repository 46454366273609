import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={295.602}
    height={74.934}
    {...props}
  >
    <title>{"186BB739-F42E-4887-A721-D6E75D1C2F8B"}</title>
    <g fill="none" fillRule="evenodd">
      <text
        fill="#FFFFFF"
        fontFamily="Century Gothic-DemiBold, Century Gothic"
        fontSize={22.4}
        fontWeight={600}
        letterSpacing={0.64}
        transform="translate(89.602 13.547)"
      >
        <tspan x={0} y={22}>
          {"SERVICIOS"}
        </tspan>
        <tspan x={0} y={46}>
          {"PROFESIONALES"}
        </tspan>
      </text>
      <path
        fill="#FFF"
        d="m39.882 1.494 6.202 3.55-12.372 12.331-5.663-5.644a2.99 2.99 0 0 0-4.221 0 2.966 2.966 0 0 0 0 4.207l7.756 7.731a2.938 2.938 0 0 0 2.094.855 3.2 3.2 0 0 0 2.128-.82l15.41-15.36a3.02 3.02 0 0 0 .216-.239l11.346 6.493a11.35 11.35 0 0 1 5.711 9.85v26.038c0 4.07-2.18 7.828-5.711 9.85L39.882 73.44a11.35 11.35 0 0 1-11.275 0L5.712 60.336A11.35 11.35 0 0 1 0 50.486V24.448c0-4.07 2.18-7.828 5.712-9.85L28.607 1.494a11.35 11.35 0 0 1 11.275 0ZM38.87 49.105H29.62a6.174 6.174 0 0 0-3.452 1.053c-.157.11-.315.236-.473.361a6.09 6.09 0 0 0-2.206 4.697v7.273c0 .69.363 1.303.946 1.65l.425.235.047.031.757.44 6.241 3.581a4.692 4.692 0 0 0 4.697 0l6.24-3.581 1.23-.707c.583-.346.946-.974.946-1.65v-7.272a6.08 6.08 0 0 0-2.222-4.697c-.158-.125-.316-.251-.473-.361a6.174 6.174 0 0 0-3.452-1.053Zm-19.275-6.298h-9.283c-2.994 0-5.484 2.12-6.02 4.948-.032.157-.032.298-.047.455-.016.204-.032.518-.032.707v.958c0 1.414.646 2.78 1.797 3.613.078.047.142.094.22.141l.032.016.142.094 12.01 6.912c.614.361 1.402-.094 1.402-.817V55.2c0-2.89 1.292-5.623 3.53-7.493.252-.204.505-.408.772-.58h.016a1.35 1.35 0 0 0 .315-1.964 6.164 6.164 0 0 0-4.854-2.356Zm38.582-.016h-9.283c-1.97 0-3.72.927-4.839 2.356a1.35 1.35 0 0 0 .316 1.964c0 .015.015.015.015.015.268.173.52.362.773.581a9.696 9.696 0 0 1 3.514 7.493v4.634a.936.936 0 0 0 1.403.817l12.01-6.912c.078-.031.125-.078.188-.11.064-.047.142-.094.205-.141 1.15-.833 1.797-2.2 1.797-3.613v-.958c0-.189-.016-.503-.031-.723a2.39 2.39 0 0 0-.048-.47 6.134 6.134 0 0 0-6.02-4.933Zm-17.421-2.393c-.56-3.574-3.92-6.02-7.506-5.463-3.587.557-6.041 3.906-5.482 7.48.559 3.575 3.92 6.021 7.506 5.464 3.586-.557 6.04-3.906 5.482-7.48ZM14.977 28.56h-.031c-3.625 0-6.572 2.938-6.572 6.55 0 3.613 2.947 6.55 6.572 6.55h.031c3.625 0 6.572-2.937 6.572-6.55 0-3.612-2.947-6.55-6.572-6.55Zm38.582 0h-.032c-3.624 0-6.572 2.938-6.572 6.55 0 3.613 2.932 6.55 6.572 6.55h.032c3.625 0 6.572-2.937 6.572-6.55 0-3.612-2.947-6.55-6.572-6.55Z"
      />
    </g>
  </svg>
)
export default SvgComponent
