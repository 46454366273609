import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={347.409}
    height={75.389}
    {...props}
  >
    <title>{"72AF9174-B889-4EA6-A6D1-1D748ABFF4E1@1x"}</title>
    <g fill="none" fillRule="evenodd">
      <text
        fill="#030144"
        fontFamily="Century Gothic-DemiBold, Century Gothic"
        fontSize={24}
        fontWeight={600}
        letterSpacing={0.9}
      >
        <tspan x={89.409} y={45.895}>
          {"INFRAESTRUCTURA"}
        </tspan>
      </text>
      <path
        fill="#030144"
        d="m32.12.243 1 1.479c.812 1.02.375 2.52-.873 2.997-.499.335-1.276.616-1.608.84-1.828 1.229-2.37 4.003-1.136 5.825 1.233 1.822 4.016 2.361 5.844 1.132.472-.196.858-.697 1.384-1.171.885-.836 2.443-.678 3.115.316l3.7 5.712-9.17 6.224c-1.137.644-.398 3.276.58 4.185.336.497.951 1.048 1.175 1.38 1.233 1.821 1.233 4.603-1.136 5.824-2.369 1.222-3.87 1.312-5.104-.51-.363-.358-1.298-1.903-1.608-2.539-.024-1.432-2.964-2.712-3.961-2.042l-9.17 6.225-3.617-5.306c-.812-1.02-.375-2.52.873-2.997.499-.335 1.276-.616 1.608-.84 1.828-1.229 2.37-4.003 1.136-5.825-1.233-1.822-4.016-2.361-5.844-1.132-.472.196-.858.697-1.384 1.171-.885.836-2.443.678-3.116-.316l-2.233-3.298A12.709 12.709 0 0 0 0 25.248v24.893c0 4.558 2.44 8.768 6.397 11.032L28.31 73.715a12.712 12.712 0 0 0 12.63 0l21.912-12.542a12.712 12.712 0 0 0 6.397-11.032V25.248c0-4.559-2.44-8.768-6.397-11.032L40.94 1.674A12.711 12.711 0 0 0 32.12.244Zm11.97 18.168 5.312 8.154c.743 1.357 2.588 1.576 3.69.438.599-.387 1.267-1.102 1.666-1.36 2.195-1.421 5.486-.724 6.911 1.463 1.426 2.188.726 5.468-1.469 6.888-.434.423-1.162.611-1.925.964-1.327.576-1.876 2.345-1.098 3.538l5.312 8.153-8.18 5.295c-1.232.939-3.007.391-3.55-1.095-.39-.596-.708-1.521-.967-1.919-1.425-2.187-4.716-2.884-6.911-1.464-2.195 1.42-2.894 4.7-1.469 6.888.224.562.812 1.03 1.366 1.66.977 1.065.756 2.904-.44 3.678l-8.181 5.295-5.312-8.153c-.743-1.357-2.588-1.577-3.69-.439-.599.387-1.267 1.103-1.666 1.361-2.195 1.42-5.486.724-6.911-1.464-1.426-2.187-.726-5.467 1.469-6.888.434-.422 1.162-.61 1.925-.963 1.326-.576 1.876-2.345 1.098-3.539l-5.312-8.153 8.18-5.295c1.232-.938 3.007-.39 3.55 1.095.39.597.708 1.522.967 1.92 1.425 2.187 4.716 2.884 6.91 1.463 2.196-1.42 2.895-4.7 1.47-6.888-.224-.561-.812-1.029-1.366-1.66-.977-1.064-.757-2.903.44-3.678l8.181-5.295Z"
      />
    </g>
  </svg>
)
export default SvgComponent
