import React from 'react'
import styled from 'styled-components'
import ContactForm  from '../landing/ContactForm'
import { Grid } from '@material-ui/core'
import CCoELogo from '../../assets/img/CCoE-logo.png'

const Info = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={8} 
      className='grid-container'>
        <Grid item container justifyContent="center" xs={12} className='logo'>
          <img width="450" height="182" src={CCoELogo} alt="ccoe logo" />
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <div className='text'>
            Creamos el centro de servicios multicloud más completo de la región para brindar todos los 
            servicios en la Nube y profesionales certificados que necesitas para encontrar valor real y oportunidades para 
            tu negocio, beneficios operativos, mejorar su eficiencia, reducir costos y mejorar la seguridad de tus datos. 
            <br /><br />
            CCoE concentra los servicios de migración y operación en la Nube, una Mesa de Soporte N1 N2 profesional y toda 
            la infraestructura, con un modelo de negocio aaS  por proyectos y estructura pensadas para atenderte siempre en prioridad. 
          </div>
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <div className="contact">
            <ContactForm  page='Cloud' location={'Contacto Web'} 
              title={'Conoce nuestros casos de éxito y la propuesta del CCoE para tu empresa:'} 
              paddingTitle='0px' />
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Info

const Styled = styled.section`
  max-width: 1200px;
  margin: 48px 0;

  img {
    @media (max-width: 640px){
      width: 250px;
      height: auto;
    }
  }

  .grid-container {
    width: 100%;
    margin: 0;
  }

  .logo {
    display: flex;
    justify-content: center;
    @media (max-width: 640px){
      margin-top: -32px;
      margin-bottom: -32px;
    }
  }

  .title{
    @media (max-width: 780px){
      text-align: start;
      padding: 0px;
    }
  }

  .title2{
    height: 40px;
    width: 500px;
    color: #000000;
    font-family: "Century Gothic";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding-top: 10px;
    @media (max-width: 780px){
      width: 300px;
      text-align: start;
    }
  }
  
  .linkAccess{
    @media (max-width: 780px){
      margin-top: 40px;
    }
  }
  
  .text {
    max-width: 100%;
    color: #030144;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
  }

  .contact {
    max-width: 100%;
    padding: 0 32px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      margin: 0px 0px 20px 0px;
      padding: 20px;
      width: 100%;
    }

    @media (max-width: 640px){
      h2 {
        font-size: 20px;
      }
      padding: 0;
    }

    button {
      background-color: #915CFD;
    }
 }
`
