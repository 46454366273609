import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={246.454}
    height={64.17}
    {...props}
  >
    <title>{"51584821-5C37-4600-BD6A-C6965E857DB5@1x"}</title>
    <g fill="#FFF" fillRule="evenodd">
      <path d="m33.31 1.108 4.546 2.601L19.85 22.498c-.359.375-.93.429-1.351.145l-.102-.078-4.224-3.66a1.055 1.055 0 0 0-1.746.798l.003 23.585a1.055 1.055 0 0 0 1.201 1.045l23.433-3.28a1.055 1.055 0 0 0 .545-1.843l-4.135-3.583a1.055 1.055 0 0 1-.072-1.527l19.486-20.353a1.054 1.054 0 0 0-.316-.89L48.56 8.996a1.055 1.055 0 0 1 .64-1.811L72.774 5.13a1.055 1.055 0 0 1 1.146 1.106L72.683 29.79a1.055 1.055 0 0 1-1.786.705l-3.95-3.802a1.055 1.055 0 0 0-1.528.067l-7.131 8.186v8.958a8.385 8.385 0 0 1-4.22 7.277L33.31 63.063a8.385 8.385 0 0 1-8.33 0L4.22 51.18A8.385 8.385 0 0 1 0 43.904V20.266a8.385 8.385 0 0 1 4.22-7.277L24.98 1.108a8.385 8.385 0 0 1 8.33 0Z" />
      <text
        fontFamily="Century Gothic-DemiBold, Century Gothic"
        fontSize={24}
        fontWeight={600}
        letterSpacing={0.9}
      >
        <tspan x={90.454} y={40.286}>
          {"MIGRACI\xD3N"}
        </tspan>
      </text>
    </g>
  </svg>
)
export default SvgComponent
